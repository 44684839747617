import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from './components/Header';
import FrontPageContent from './components/FrontPageContent';
import Footer from './components/Footer';

import './App.scss';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <ParallaxProvider>

                <Header />
                <Container>
                    <FrontPageContent />
                </Container>
                <Footer />

            </ParallaxProvider>
        </ThemeProvider>
    );
}

export default App;
