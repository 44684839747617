
import React from 'react';
import EmailForm from '../components/EmailForm'
import { SocialIcon } from 'react-social-icons';
import { ParallaxBanner } from 'react-scroll-parallax';
import ScreenImage01 from '../assets/screen01.png'
import ScreenImage02 from '../assets/screen02.png'
import ScreenImage03 from '../assets/screen03.png'
import ScreenImage04 from '../assets/screen04.png'
import ScreenImage05 from '../assets/screen05.png'
import './FrontPageContent.scss'

const FrontPageContent = () => {

    return (
        <>

            <div className="announcementSection">
                <div className='sub-header'>
                    introducing
                </div>
                <div className='header'>
                    Nova Patria
                </div>
                <br></br>
                <div className='sub-header'>
                    Discover Nova Patria – Inside the Devlog
                </div>
                <div className='videoContainer'>
                    <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/?listType=playlist&list=PLyezjG2qP1t3x91OZVOwd1AMgIc1WvdVO&modestbranding=1&rel=0&index=9"
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                    allowFullScreen></iframe>
                </div>


                <div className='sub-header'>
                    Featured in GamerZakh's Top 30 Upcoming<br />City Builders
                </div>
                <div className='videoContainer'>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/D-MXFPeLjts?start=1372&modestbranding=1&rel=0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen></iframe>
                </div>


            </div>

            <div className='callToActionSection'>
                <div className="socialIconsContainer">
                    <SocialIcon url="https://discord.gg/jPsPvhMSYv" target="_blank"/>
                    <SocialIcon url="https://www.youtube.com/channel/UCFRaUsX3l4rnQBnksyhVIhg/featured" target="_blank"/>
                    <SocialIcon url="https://www.reddit.com/user/SowerInteractive/?sort=top&t=all" target="_blank"/>
                    <SocialIcon url="https://twitter.com/SowerGames" target="_blank"/>
                    <SocialIcon url="https://www.instagram.com/sowerinteractive" target="_blank"/>
                    <SocialIcon url="mailto:contact@sowerinteractive.com" target="_blank"/>
                    <EmailForm />
                </div>
            </div>

            <ParallaxBanner
                layers={[{ image: ScreenImage01, speed: -15 }]}
                className="parallaxBanner" style={{ height: "300px" }}
            />
            <ParallaxBanner
                layers={[{ image: ScreenImage02, speed: -15 }]}
                className="parallaxBanner" style={{ height: "300px" }}
            />
            <ParallaxBanner
                layers={[{ image: ScreenImage03, speed: -15 }]}
                className="parallaxBanner" style={{ height: "300px" }}
            />
            <ParallaxBanner
                layers={[{ image: ScreenImage04, speed: -15 }]}
                className="parallaxBanner" style={{ height: "300px" }}
            />
            <ParallaxBanner
                layers={[{ image: ScreenImage05, speed: -15 }]}
                className="parallaxBanner" style={{ height: "300px" }}
            />
        </>
    );
};


export default FrontPageContent;