
import React from 'react';
import './Header.scss'
import Logo from '../assets/logo.png'
import { SocialIcon } from 'react-social-icons';

const Header = () => {
    return (
        <div className='header'>
            <img src={Logo} />
            <div className='emailContainer'>
                <SocialIcon url='mailto:contact@sowerinteractive.com' target='_blank'/>
            </div>
        </div>
    );
};


export default Header;