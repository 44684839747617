import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import './EmailForm.scss'

const url = "https://sowerinteractive.us8.list-manage.com/subscribe/post?u=13d28883c3fbbe44003026f07&amp;id=4adbb4442d";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

const EmailForm = () => {
    return (
        <div className="emailForm">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <div className='emailFormInputContainer'>
                        <p>Sign up for our mailing list!</p>

                        <SimpleForm onSubmitted={formData => subscribe(formData)} />
                        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
                        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                    </div>
                )}
            />
        </div>
    );
};


export default EmailForm;